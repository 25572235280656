<template>
  <b-card class="box-shadow-0 intentForm">
    <b-card-title>
      Intent {{ intent.t_index }}
    </b-card-title>
    <div class="pb-0">
      <validation-observer ref="intent-form">
        <b-row class="mb-1">
          <b-col md="6">
            <b-form-group
              label="Title"
              label-for="intent-title"
            >
              <validation-provider
                #default="{ errors }"
                name="title"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="intent-title"
                    v-model="intentData.title"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Location"
              label-for="intent-location"
            >
              <b-input-group>
                <b-form-input
                  id="intent-location"
                  v-model="intentData.location"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Time"
              label-for="intent-time"
            >
              <b-input-group>
                <cleave
                  id="intent-time"
                  v-model="intentData.time"
                  class="form-control"
                  :raw="false"
                  :options="options.time"
                  placeholder="hh:mm"
                />
                <b-input-group-append>
                  <b-form-timepicker
                    id="intent-time"
                    v-model="intentData.time"
                    button-variant="outline-primary"
                    locale="en"
                    size="sm"
                    button-only
                    right
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Duration"
              label-for="intent-duration"
            >
              <b-input-group>
                <b-form-input
                  id="intent-duration"
                  v-model="intentData.duration"
                  placeholder="DD HH:MM:SS"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Category"
              label-for="intent-category"
            >
              <validation-provider
                #default="{ errors }"
                name="category"
                rules="required"
              >
                <v-select
                  id="intent-category"
                  v-model="intentData.category"
                  :options="categories"
                  :reduce="category => category.value"
                  label="name"
                  class="w-100"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Index"
              label-for="intent-t_index"
            >
              <validation-provider
                #default="{ errors }"
                name="index"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="intent-t_index"
                    v-model="intentData.t_index"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Descriptions"
              label-for="intent-description"
            >
              <quill-editor
                id="intent-description"
                ref="overview"
                v-model="intentData.description"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Feature Picture"
                  label-for="intent-feature-picture"
                >
                  <b-form-file
                    id="intent-feature-picture"
                    v-model="featuredImage"
                    accept=".jpg, .jpeg, .png"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    @input="renderFeaturePicture()"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <div
              v-if="intentData.featured_picture || imageUrl"
              class="image-list d-flex flex-wrap"
            >
              <div
                class="image-wrapper"
              >
                <feather-icon
                  icon="XIcon"
                  class="remove-icon"
                  @click="removeFeaturePicture()"
                />
                <b-img
                  :src="imageUrl ? imageUrl : intentData.featured_picture"
                  thumbnail
                  fluid
                />
              </div>
            </div>
          </b-col>
          <b-col md="12">
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Picture"
                  label-for="intent-picture"
                >
                  <b-form-file
                    id="intent-picture"
                    v-model="intentPictures"
                    multiple
                    accept=".jpg, .jpeg, .png"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    @input="uploadPicture()"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <div class="image-list d-flex flex-wrap">
              <draggable
                v-model="intentData.pictures"
                class="d-flex"
                @change="changeImageOrder($event,intentData.pictures)"
              >
                <div
                  v-for="(picture, p) in intentData.pictures"
                  :id="p"
                  :key="p"
                  class="image-wrapper"
                >
                  <feather-icon
                    icon="XIcon"
                    class="remove-icon"
                    @click="removePicture(picture, p)"
                  />
                  <b-img
                    :src="picture.img"
                    thumbnail
                    fluid
                  />
                </div>
              </draggable>
            </div>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-end">
          <b-button
            variant="outline-primary"
            class="mr-2"
            @click="$emit('close')"
          >
            Close
          </b-button>
          <b-button
            variant="primary"
            :disabled="isBtnLoading"
            @click="isTourInfoValid()"
          >
            <b-spinner
              v-if="isBtnLoading"
              small
            />
            {{ intent.id ? 'Update':'Submit' }}
          </b-button>
        </div>
      </validation-observer>
    </div>
  </b-card>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Cleave from 'vue-cleave-component'
import { required } from '@validations'
import draggable from 'vuedraggable'
import api from '@/libs/api'
import helper from '@/mixins/helper'
import tour from '@/mixins/tour'

export default {
  name: 'IntentForm',
  components: {
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    Cleave,
    draggable,
  },
  mixins: [helper, tour],
  props: {
    intent: {
      type: Object,
    },
    id: {
      type: [String, Number],
    },
  },
  data() {
    return {
      required,
      isBtnLoading: false,
      intentPictures: [],
      featuredImage: null,
      imageUrl: '',
      intentData: {
        day_intent: '',
        title: '',
        location: '',
        time: '',
        duration: '',
        category: '',
        t_index: '',
        description: '',
        pictures: [],
        featured_picture: null,
      },
      categories: [
        { value: 1, name: 'Stop' },
        { value: 2, name: 'Overview' },
        { value: 3, name: 'Break' },
        { value: 4, name: 'Drive' },
        { value: 5, name: 'Food Break' },
        { value: 6, name: 'Sightseeing' },
      ],
      isFeatureUploaded: false,
      options: {
        time: {
          time: true,
          timePattern: ['h', 'm'],
        },
      },
    }
  },
  mounted() {
    if (this.intent.id) {
      this.intentData = JSON.parse(JSON.stringify(this.intent))
      this.intentData.category = this.intent.category.id
      // console.log(this.intentData.pictures)
    } else {
      this.intentData.day_intent = this.id
    }
  },
  methods: {
    isTourInfoValid() {
      if (!this.intentData.id) {
        this.$refs['intent-form'].validate().then(success => {
          if (success) {
            this.createOrEditIntent()
          } else {
            this.notificationMessage('warning', 'XIcon', 'Error', 'Please fill up all required fields')
          }
        })
      } else {
        this.createOrEditIntent()
      }
    },
    createOrEditIntent() {
      const intent = this.intentData
      const intentData = {
        day_intent: intent.day_intent,
        t_index: intent.t_index,
        title: intent.title,
        time: intent.time,
        duration: intent.duration,
        description: intent.description,
        location: intent.location,
        category: intent.category,
        pictures: intent.pictures.map(x => x.id),
      }
      if (intent.id) {
        intentData.id = intent.id
      }
      this.callIntentApi(intentData, intentData.id)
    },
    callIntentApi(formData, id) {
      this.isBtnLoading = true
      let url = 'tour/admin_services/tour_intent_create/'
      let apiResponse
      if (id) {
        url = `tour/admin_services/tour_intent_update/${id}/`
        apiResponse = api.updateData(url, true, formData)
      } else {
        apiResponse = api.postData(url, true, formData)
      }
      apiResponse.then(response => {
        if (response.data.status) {
          if (this.intentData.featured_picture && typeof this.intentData.featured_picture !== 'string' && !this.isFeatureUploaded) {
            const featuredImageFile = new FormData()
            featuredImageFile.append('featured_picture', this.intentData.featured_picture)
            this.callIntentApi(featuredImageFile, response.data.result.id)
            this.isFeatureUploaded = true
          }
          this.getTour()
          this.notificationMessage('success', 'CheckIcon', 'Success', `Itinerary intent ${this.intent.id ? 'updated' : 'created'} successfully`)
          setTimeout(() => {
          }, 500)
        } else {
          this.notificationMessage('danger', 'Xicon', 'Error', JSON.stringify(response.data.error.message))
          this.isFeatureUploaded = false
        }
      }).catch(() => {
        this.isFeatureUploaded = false
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
      }).finally(() => {
        this.isBtnLoading = false
      })
    },
    uploadPicture() {
      const file = this.intentPictures
      const intent = this.intentData.pictures
      let maxIndex = intent.length > 0 ? intent.sort((a, b) => (b.p_index - a.p_index))[0].p_index + 1 : 1
      for (let i = 0; i < file.length; i += 1) {
        const formData = new FormData()
        formData.append('img', file[i])
        formData.append('p_index', maxIndex)
        maxIndex += 1
        api.postData('tour/admin_services/tour_picture/', true, formData).then(response => {
          if (response.data.status) {
            this.intentData.pictures.push(response.data.result)
            this.notificationMessage('success', 'CheckIcon', 'Success', 'Picture uploaded successfully')
          } else {
            this.notificationMessage('danger', 'Xicon', 'Error', JSON.stringify(response.data.error.message))
          }
        }).catch(() => {
          this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
        })
      }
    },
    renderFeaturePicture() {
      this.imageUrl = URL.createObjectURL(this.featuredImage)
      this.intentData.featured_picture = this.featuredImage
      this.isFeatureUploaded = false
    },
    removePicture(image, index) {
      const formData = {
        id: image.id,
        p_index: image.p_index,
      }
      api.updateData(`tour/admin_services/tour_picture/${image.id}/?delete_old=true`, true, formData).then(response => {
        if (response.data.status) {
          this.intentData.pictures.splice(index, 1)
          this.notificationMessage('success', 'CheckIcon', 'Success', 'Picture removed successfully')
          this.$emit('getTour')
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
      })
    },
    removeFeaturePicture() {
      const formData = {
        featured_picture: null,
      }
      this.callIntentApi(formData, this.intent.id)
    },
    getTour() {
      setTimeout(() => {
        this.$emit('close')
        this.$emit('getTour')
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
.intentForm {
  .image-wrapper {
    width: 150px;
    height: 150px;
    margin: 5px 10px 10px 0;
    position: relative;
    .remove-icon {
      position: absolute;
      top: -5px;
      right: -5px;
      background-color: #eceaff;
      padding: 5px;
      border-radius: 10px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
