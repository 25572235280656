<template>
  <b-card
    class="box-shadow-0 itinerary-list"
  >
    <b-card-title
      v-if="itineraryData"
      class="d-flex justify-content-between align-items-center"
    >
      Itinerary List
      <b-button
        variant="outline-primary"
        @click="addDay()"
      >
        <feather-icon
          icon="PlusIcon"
          size="16"
          class="align-middle text-body edit"
        />
        Add Day
      </b-button>
    </b-card-title>
    <b-card-body class="pb-0 border-top">
      <b-row
        v-for="(itinerary,i) in itineraryData"
        :key="i"
        class="mb-1"
      >
        <div class="section-header">
          <div class="d-flex align-items-center">
            <h4 class="mb-0 font-weight-bolder text-primary">
              {{ itinerary.title }}
            </h4>
            <b-badge
              pill
              variant="light-primary badge-btn"
              class="mr-1"
              @click="editDay(itinerary)"
            >
              <feather-icon
                icon="Edit2Icon"
                size="16"
                class="align-middle text-primary edit"
              />
              Edit
            </b-badge>
            <b-badge
              v-b-modal.itinerary-modal
              pill
              variant="light-danger badge-btn"
              @click="getSelectedData(itinerary, 'day_intent_delete')"
            >
              <feather-icon
                icon="TrashIcon"
                size="16"
                class="align-middle edit text-danger"
              />
              Delete
            </b-badge>
          </div>
          <div class="section-btn">
            <b-button
              variant="outline-primary"
              size="sm"
              @click="addIntent(itinerary.id)"
            >
              Add Intent
            </b-button>
          </div>
        </div>
        <b-table
          id="reportTable"
          :items="itinerary.tour_intent"
          primary-key="id"
          responsive
          show-empty
          thead-class="d-none"
          :fields="tableColumns"
          class="position-relative"
        >
          <template #cell(actions)="data">
            <b-badge
              pill
              variant="light-primary badge-btn mr-1"
              @click="editIntent(data.item, itinerary.id)"
            >
              <feather-icon
                icon="Edit2Icon"
                size="16"
              />
              Edit
            </b-badge>
            <b-badge
              v-b-modal.itinerary-modal
              pill
              variant="light-danger badge-btn"
              @click="getSelectedData(data.item, 'tour_intent_delete')"
            >
              <feather-icon
                icon="TrashIcon"
                size="16"
              />
              Delete
            </b-badge>
          </template>
        </b-table>
      </b-row>
    </b-card-body>
    <b-modal
      id="itinerary-modal"
      ref="itinerary-modal"
      ok-variant="danger"
      modal-class="modal-danger"
      centered
      title="Delete"
    >
      <b-card-text>
        Are you sure you want to delete this {{ selectedIntent.tour_intent ? 'day' : 'tour intent' }} ?
      </b-card-text>
      <template #modal-footer>
        <b-button
          variant="danger"
          :disabled="isRequestOnProgress"
          @click="deleteDayIntent()"
        >
          <b-spinner
            v-if="isRequestOnProgress"
            small
          />
          Confirm
        </b-button>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import api from '@/libs/api'
import helper from '@/mixins/helper'

export default {
  name: 'ItineraryList',
  mixins: [helper],
  props: {
    itineraryData: {
      type: Array,
    },
    tourId: {
      type: [Number, String],
    },
  },
  data() {
    return {
      isRequestOnProgress: false,
      selectedIntent: {},
      deleteEndpoint: '',
      intentId: '',
      tableColumns: [
        {
          key: 'time', label: '', thClass: 'md', formatter: value => this.formatTime(value),
        },
        {
          key: 'title', label: '', thClass: 'md',
        },
        {
          key: 'category.name', label: '', thClass: 'md', formatter: value => value || 'N/A',
        },
        { key: 'actions', label: '' },
      ],
    }
  },
  methods: {
    editIntent(data, dayId) {
      const intent = data
      intent.day_intent = dayId
      this.$emit('editIntent', intent)
    },
    editDay(dayIntent) {
      this.$emit('editDayForm', dayIntent)
    },
    addIntent(intentId) {
      this.$emit('addIntent', intentId)
    },
    addDay() {
      const numOfDay = this.itineraryData.length + 1
      const data = {
        t_index: numOfDay,
        title: `Day ${numOfDay}`,
        tour: this.tourId,
      }
      this.$emit('addDay', data)
    },
    getSelectedData(data, endpoint) {
      this.selectedIntent = data
      this.deleteEndpoint = endpoint
    },
    deleteDayIntent() {
      this.isRequestOnProgress = true
      api.deleteData(`tour/admin_services/${this.deleteEndpoint}/${this.selectedIntent.id}/`, true)
        .then(response => {
          if (response.data.status) {
            this.$emit('getItineraryList')
            this.$root.$emit('bv::hide::modal', 'itinerary-modal')
            this.notificationMessage('success', 'CheckIcon', 'Success', 'Day intent has been deleted successfully')
          } else {
            this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
          }
        }).catch(() => {
          this.notificationMessage('danger', 'XIcon', 'Error', '')
        }).finally(() => {
          this.isRequestOnProgress = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.itinerary-list {
  .section-header {
    width: 100%;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h4 {
      margin-right: 5px;
    }
  }
  .badge-btn {
    cursor: pointer;
  }
}
</style>
