<template>
  <b-card class="box-shadow-1" no-body>
    <DayForm
      v-if="isDayForm"
      :day="dayData"
      @getTour="getTour()"
      @close="closeDayForm"
    />
    <IntentForm
      v-if="isIntentForm"
      :id="intentId"
      :intent="intent"
      @getTour="getTour()"
      @close="closeDayForm"
    />
    <ItineraryList
      v-if="isItineraryList"
      :itinerary-data="tourIntentData"
      :tour-id="tourId"
      @addDay="addDay"
      @addIntent="addIntent"
      @editDayForm="editDayForm"
      @editIntent="editIntentForm"
      @getItineraryList="getTour"
    />
  </b-card>
</template>

<script>
import api from '@/libs/api'
import DayForm from './itinerary/DayForm.vue'
import IntentForm from './itinerary/IntentForm.vue'
import ItineraryList from './itinerary/ItineraryList.vue'
import tour from '@/mixins/tour'
import helper from '@/mixins/helper'

export default {
  name: 'Itinerary',
  components: {
    DayForm,
    IntentForm,
    ItineraryList,
  },
  mixins: [tour, helper],
  data() {
    return {
      tourIntentData: [],
      tourId: this.$store.state.tour.property.id,
      intentId: null,
      dayData: {},
      intent: {},
      isDayForm: false,
      isIntentForm: false,
      isItineraryList: true,
    }
  },
  mounted() {
    this.isTourValid(this.$route.params.id, this.getTour)
  },
  methods: {
    getTour() {
      api.getData(`tour/admin_services/tour/${this.$route.params.id}/tour_intents/`, true).then(response => {
        if (response.data.status) {
          this.tourIntentData = response.data.result
          this.istourIntentDataLoaded = true
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
          if (response.data.error.code === 400) {
            this.$router.push('/')
          }
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong')
      })
    },
    editDayForm(data) {
      this.dayData = data
      this.isDayForm = true
      this.isItineraryList = false
    },
    editIntentForm(intent) {
      this.intent = intent
      this.isIntentForm = true
      this.isItineraryList = false
    },
    addIntent(intentId) {
      this.intentId = intentId
      this.isIntentForm = true
      this.isItineraryList = false
    },
    addDay(newDay) {
      this.dayData = newDay
      this.isDayForm = true
      this.isItineraryList = false
    },
    closeDayForm() {
      this.isDayForm = false
      this.isIntentForm = false
      this.isItineraryList = true
      this.dayData = {}
      this.intent = {}
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
